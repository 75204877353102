























































































import Vue from "vue";
export default Vue.extend({
  name: "ServiceClassForm",
  data() {
    return {
      e1: 1,
      steps: 2,
      title: "Please pick if it’s a single or class service",
      class_: false,
    };
  },
});
